import { useEffect } from "react";
import { useMiloStore } from "../../components/Milo/stores/miloStore";
import { useSendMessage } from "../../components/Milo/hooks/miloHooks";
import { Avatar, Flex, Group, Text } from "@mantine/core";
import { ChatBox } from "../../components/Milo/Chatbox";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { usePrimaryWallet } from "../../hooks/usePrimaryWallet";
import { useCheckoutStore } from "../../components/Checkout/checkoutStore";
import { appendPurchaseMessages, generateSessionId } from "../../utils/milo";
import useAuth from "../../hooks/useAuth";
import { useTicketTransferStore } from "../TicketTransfer/stores/ticketTransferStore";
import { useUserStore } from "../Profile/store/userStore";
import { useBuyTicketStore } from "../../components/BuyTicket/buyTicketStore";

const MiloPage = () => {
  const { primaryWallet } = usePrimaryWallet();
  const { mutateAsync: sendMessage, isPending } = useSendMessage();
  const { boughtTickets, isMiloBuySuccess, setIsMiloBuySuccess } =
    useCheckoutStore();
  const { event } = useBuyTicketStore();
  const { ticket, prompt, selectedUsers, reset } = useTicketTransferStore();
  const [searchParams] = useSearchParams();
  const suggestion = searchParams.get("suggestion");

  const navigate = useNavigate();
  const { loggedInUser } = useAuth();
  const { userCountry } = useUserStore();

  const {
    setIsQuickMessage,
    messages,
    setMessages,
    setIsInitialMessageSent,
    isInitialMessageSent,
    sessionId,
    setSessionId,
  } = useMiloStore();

  const resetSession = async () => {
    const newSessionId = generateSessionId();
    setSessionId(loggedInUser?.id + "-" + newSessionId);
    setMessages([]);
    setIsInitialMessageSent(false);
    setIsQuickMessage(false);

    await initialMessage(true, loggedInUser?.id + "-" + newSessionId);
  };

  const sendSuggestion = async (reset) => {
    const lastMessage = messages[messages.length - 1];

    if (lastMessage?.trigger === "events_quick_chat" && !reset) return;
    else if (reset) {
      setMessages([
        { type: "user", content: "Recommend an event for me" },
        {
          type: "bot",
          content: "Sure! What should I base my recommendation on?",
          trigger: "events_quick_chat",
        },
      ]);
    } else {
      setMessages([
        ...messages,
        { type: "user", content: "Recommend an event for me" },
        {
          type: "bot",
          content: "Sure! What should I base my recommendation on?",
          trigger: "events_quick_chat",
        },
      ]);
    }
  };

  const initialMessage = async (reset = false, newSessionId) => {
    if (suggestion === "events") return sendSuggestion(reset);

    setMessages([{ type: "bot", content: "Milo is thinking..." }]);
    const data = await sendMessage({
      message: "Hello",
      id: newSessionId || sessionId,
    });
    if (reset) {
      setMessages([{ type: "bot", content: data?.message }]);
    } else {
      setMessages([...messages, { type: "bot", content: data?.message }]);
    }
    setIsInitialMessageSent(true);
  };

  useEffect(() => {
    if (!loggedInUser) {
      navigate("/login");
    }
  }, [loggedInUser]);

  useEffect(() => {
    const init = async () => {
      setIsMiloBuySuccess(false);
      if (!isInitialMessageSent) {
        await resetSession();
      }
    };

    init();

    return () => setIsMiloBuySuccess(false);
  }, []);

  useEffect(() => {
    if (!suggestion) return;

    if (suggestion !== "events") return;

    sendSuggestion();
  }, [searchParams]);

  useEffect(() => {
    if (messages == undefined || messages == null) setMessages([]);
  }, [messages]);

  useEffect(() => {
    const metaViewport = document.querySelector("meta[name=viewport]");
    metaViewport.setAttribute(
      "content",
      "width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
    );
  }, []);

  useEffect(() => {
    if (!boughtTickets?.length || !isMiloBuySuccess) return;

    appendPurchaseMessages(boughtTickets, messages, setMessages, event);
  }, [boughtTickets, isMiloBuySuccess]);

  useEffect(() => {
    if (!prompt || !ticket || !selectedUsers?.length) return;

    const { username } = selectedUsers[0] || {};

    setMessages([
      ...messages,
      {
        type: "bot",
        content: `Are you sure you want to transfer this ticket to @${username}? This is irreversible.`,
        trigger: "transfer_ticket_confirm",
        ticket,
        prompt,
      },
    ]);
  }, [prompt]);

  useEffect(() => {
    if (!loggedInUser) resetSession();

    if (!sessionId?.includes(loggedInUser?.id)) {
      resetSession();
    }
  }, [loggedInUser, sessionId]);

  const handleSendMessage = async ({ message }) => {
    setIsQuickMessage(true);
    setMessages([
      ...messages,
      { type: "user", content: message },
      { type: "bot", content: "Milo is thinking..." },
    ]);

    const data = await sendMessage({
      message,
      id: sessionId,
      address: primaryWallet?.address,
      country: userCountry,
      userRealId: loggedInUser?.id,
    });

    setMessages([
      ...messages,
      { type: "user", content: message },
      {
        type: "bot",
        content: data?.message,
        additionalContent: data?.additional_message,
        events: data?.content,
        finished: data?.finished,
        trigger: data?.trigger,
      },
    ]);
  };

  return (
    <Flex
      direction="column"
      style={{
        height: "100dvh",
        position: "relative",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          padding: 16,
          position: "sticky",
          top: 0,
          width: "100%",
          backgroundColor: "#11111F",
          zIndex: 1000,
        }}
      >
        <Link to={`/events`}>
          <Group align="center" gap="8px">
            <BackButton
              onClick={() => {
                navigate("/events");
                reset();
              }}
            />
            <Avatar
              src="/images/milo/milo.gif"
              style={{
                marginLeft: -18,
              }}
            />
            <Text
              style={{
                color: "#FFFFFF",
                fontSize: "20px",
                fontFamily: "Suisse Intl",
                fontWeight: 600,
                lineHeight: "100%",
              }}
            >
              Ask Milo
            </Text>
            <Text
              style={{
                fontFamily: "Suisse Int'l",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "30px",
                marginLeft: "auto",
                color: "#FFFFFF",
                opacity: 0.6,
                width: "79px",
                height: "32px",
                gap: "8px",
                borderRadius: "10px",
                backgroundColor: "#2D2D52",
                textAlign: "center",
              }}
              onClick={(e) => {
                e.preventDefault();
                resetSession();
              }}
            >
              clear chat
            </Text>
          </Group>
        </Link>
      </div>

      <Flex
        w={"100%"}
        style={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <ChatBox
          messages={messages}
          onSendMessage={handleSendMessage}
          onReset={resetSession}
          isPending={isPending}
          setMessages={setMessages}
        />
      </Flex>
    </Flex>
  );
};

export default MiloPage;
