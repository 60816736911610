import EventQuickChat from "./EventQuickChat";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { useEventQuickChats } from "../../hooks/miloHooks";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100vw;
  height: 100%;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
  padding: 0 40px;
  margin: 0 auto;
  height: 100%;
  overflow-x: auto;
  width: 100vw;
`;

export default function EventQuickChats({ onSelect }) {
  const eventQuickChats = useEventQuickChats();
  const [searchParams] = useSearchParams();
  const suggestion = searchParams.get("suggestion");

  if (suggestion !== "events") return null;

  return (
    <Container>
      <RowContainer>
        {eventQuickChats.slice(0, 3).map((message, index) => (
          <EventQuickChat key={index} message={message} onSelect={onSelect} />
        ))}
      </RowContainer>
      {/* <RowContainer>
        {eventQuickChats.slice(3, 6).map((message, index) => (
          <EventQuickChat key={index} message={message} onSelect={onSelect} />
        ))}
      </RowContainer> */}
    </Container>
  );
}

EventQuickChats.propTypes = {
  messages: PropTypes.array,
  onSelect: PropTypes.func,
};
