import { ButtonRectSm, FooterText } from "../../../../GlobalStyles";
import PropTypes from "prop-types";

export default function EventQuickChat({ message, onSelect }) {
  const { icon, text, response } = message || {};

  return (
    <ButtonRectSm
      primary
      onClick={() =>
        onSelect({
          message: response,
        })
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
        }}
      >
        {icon}
      </div>
      <FooterText
        style={{
          textWrap: "nowrap",
        }}
      >
        {text}
      </FooterText>
    </ButtonRectSm>
  );
}

EventQuickChat.propTypes = {
  message: PropTypes.object,
  onSelect: PropTypes.func,
};
