import { Group, ScrollArea } from "@mantine/core";
import { EventBox } from "./EventBox";
import PropTypes from "prop-types";

export const EventContainer = ({ events }) => {
  return (
    <ScrollArea
      scrollbarSize={2}
      style={{
        maxWidth: "100%",
        minHeight: "auto",
        display: "flex",
        flexDirection: "row",
        gap: 16,
        justifyContent: "center",
        alignItems: "center",
        height: "auto",
      }}
      styles={{
        root: {
          minHeight: "auto",
          height: "auto",
        },
        viewport: {
          minHeight: "auto",
          height: "auto",
        },
      }}
      onWheelCapture={(event) => {
        // Prevent the default vertical scroll
        // event.preventDefault();

        // Get the scrollable element
        const scrollContainer = event.currentTarget;

        // Scroll horizontally instead of vertically
        scrollContainer.scrollLeft += event.deltaY;
      }}
      type="auto"
    >
      <Group
        gap={16}
        style={{
          flexWrap: "nowrap",
          justifyContent: "center",
          padding: "0 46px",
        }}
        align="start"
      >
        {events
          ?.sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((event, index) => {
            return (
              <EventBox
                key={index}
                id={event?.id ?? event?.event_id}
                artistName={event?.event_name}
                headlineArtistId={event?.headline_artist_id}
                headlineArtist={event?.headline_artist}
                venue={event?.venue_name}
                date={event?.date}
                imageUrl={event?.image}
                ticketImage={event?.ticket_image}
                urls={event?.urls}
              />
            );
          })}
      </Group>
    </ScrollArea>
  );
};

EventContainer.propTypes = {
  events: PropTypes.array,
};
