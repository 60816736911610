import { useEffect, useState } from "react";
import { BodyText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import QuickSearchItem from "../QuickSearchItem";
import {
  QuickSearchContainer,
  QuickSearchItemsContainer,
} from "./QuickSearch-Styles";
import useAuth from "../../../hooks/useAuth";

const { colors } = theme;

export default function QuickSearch() {
  const { loggedInUser } = useAuth();
  const [location, setLocation] = useState("");

  // Get location from user
  const city = location ? location?.split(",")?.[0]?.trim() : "London";
  const country = location
    ? location?.split(",")?.[1]?.trim()
    : "United Kingdom";

  // Set home location when user logs in
  useEffect(() => {
    if (loggedInUser)
      setLocation(loggedInUser.location ? loggedInUser.location : "");
  }, [loggedInUser]);

  return (
    <QuickSearchContainer>
      <BodyText weight="600" color={colors.grey}>
        Start browsing
      </BodyText>
      <QuickSearchItemsContainer>
        <QuickSearchItem
          text={"Search by city"}
          filter={"locationPicker=true"}
          bg={colors.quickSearchBlue}
        />
        <QuickSearchItem
          text={`Search in ${city}`}
          filter={`tab=event&country=${country}&city=${city}&quickSearch=true`}
          bg={colors.quickSearchRed}
        />
        <QuickSearchItem
          text={"Search by venue"}
          filter={`tab=venue`}
          bg={colors.quickSearchPurple}
        />
        <QuickSearchItem
          text={"Near you this month"}
          filter={`tab=event&month=${new Date().getMonth() + 1}&quickSearch=true`}
          bg={colors.quickSearchGreen}
        />
      </QuickSearchItemsContainer>
    </QuickSearchContainer>
  );
}
