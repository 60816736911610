import { Group, Image, Stack, Text, Textarea } from "@mantine/core";
import { BotMessage } from "./BotMessage";
import { UserMessage } from "./UserMessage";
import cssstyles from "./chatbox.module.css";
import React, { useEffect, useRef, useState } from "react";
import { QuickChat } from "./QuickChat";
import { useMiloStore } from "./stores/miloStore";
import { EventContainer } from "./EventContainer";
import { useScrollIntoView } from "@mantine/hooks";
import PropTypes from "prop-types";
import Wallet from "./Wallet";
import { EarnMore } from "./EarnMore";
import TransferTicketButton from "./components/TransferTicketButton";
import TicketItem from "../Tickets/TicketItem";
import ConfirmTransferTicketButton from "./components/ConfirmTransferTicketButton";
import EventQuickChats from "./components/EventQuickChats/EventQuickChats";

export const ChatBox = ({ messages, onSendMessage, isPending }) => {
  const { isQuickMessage, setMessages } = useMiloStore();
  const [message, setMessage] = useState("");
  const inputRef = useRef < HTMLTextAreaElement > null;

  const { targetRef } = useScrollIntoView({
    offset: 60,
  });

  useEffect(() => {
    if (messages?.length > 0) {
      setTimeout(() => {
        targetRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() && !isPending) {
      onSendMessage({ message: message.trim() });
      setMessage("");
    }
  };

  const handleSendClick = () => {
    if (message.trim() && !isPending) {
      onSendMessage({ message: message.trim() });
      setMessage("");

      setTimeout(() => {
        targetRef.current?.scrollIntoView({ behavior: "smooth" });
        inputRef.current?.focus();
      }, 100);
    }
  };

  useEffect(() => {
    if (!isPending) {
      setTimeout(() => {
        targetRef.current?.scrollIntoView({ behavior: "smooth" });
        inputRef.current?.focus();
      }, 100);
    }
  }, [isPending]);

  useEffect(() => {
    if (
      messages?.[messages?.length - 1]?.events ||
      (messages?.[messages?.length - 1]?.trigger === "balance_tool" &&
        !messages?.[messages?.length - 1]?.ticket) ||
      messages?.[messages?.length - 1]?.isTransferSuccess
    ) {
      setMessages([
        ...messages,
        {
          type: "bot",
          content: "Can I help you with anything else? 😊",
          trigger: "quick_chat",
        },
      ]);
    }
  }, [messages]);

  // Add this function to handle modal closing
  useEffect(() => {
    // Prevent iOS from zooming on input focus
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    const originalContent = viewportMeta?.getAttribute("content");
    viewportMeta?.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1"
    );

    return () => {
      // Restore original viewport meta when modal closes
      if (originalContent) {
        viewportMeta?.setAttribute("content", originalContent);
      }
    };
  }, []);

  return (
    <Stack
      gap="16px"
      style={{
        margin: "0 auto",
        height: "100%",
        width: "100%",
        maxWidth: "578px",
        overflowY: "hidden",
      }}
      justify="flex-end"
    >
      <Stack
        gap="16px"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          minHeight: 0,
          maxHeight: "100%",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::WebkitScrollbar": {
            display: "none",
          },
          padding: "0 20px",
        }}
      >
        {messages?.map((message, index) => {
          return message.type === "bot" ? (
            <React.Fragment key={index}>
              {index < messages?.length - 1 &&
                messages?.[index + 1]?.isTicketPurchase &&
                messages?.[index]?.isTicketPurchase && (
                  <Group gap={12} ml={"auto"} mr={"auto"}>
                    <Image src="/images/milo/tickets.svg" alt="tickets" />
                    <Text
                      style={{
                        fontFamily: "Suisse Intl",
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "white",
                      }}
                    >
                      You bought {messages?.[index + 1]?.totalTickets}{" "}
                      {Number(messages?.[index + 1]?.totalTickets) > 1
                        ? "tickets"
                        : "ticket"}
                      !
                    </Text>
                  </Group>
                )}
              {message?.trigger === "transfer_ticket_confirm" &&
              message?.ticket ? (
                <TicketItem ticket={message?.ticket} />
              ) : null}
              <BotMessage
                message={message.content}
                showAvatar={index == 0 || messages?.[index - 1]?.type != "bot"}
              />
              {message?.additionalContent && (
                <BotMessage
                  message={message.additionalContent}
                  showAvatar={false}
                />
              )}

              {message?.events && message?.events?.length > 0 && (
                <div
                  style={{
                    height: "100%",
                    marginLeft: "-20px",
                    marginRight: "-20px",
                  }}
                >
                  <EventContainer events={message?.events} />
                </div>
              )}
              {message?.trigger === "balance_tool" ? (
                <Wallet message={message} />
              ) : null}
              {message?.trigger === "transfer_ticket" &&
              messages?.[messages?.length - 1]?.content
                ?.toLowerCase()
                .includes("ticket to another fan?") ? (
                <TransferTicketButton
                  onSendMessage={onSendMessage}
                  messages={messages}
                />
              ) : null}
              {message?.trigger === "transfer_ticket_confirm" &&
              messages?.[messages?.length - 1]?.content
                ?.toLowerCase()
                .includes("are you sure you want to transfer") ? (
                <ConfirmTransferTicketButton
                  ticket={message?.ticket}
                  prompt={message?.prompt}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <UserMessage key={index} message={message.content} />
          );
        })}
        {/* GENERAL QUICK CHATS */}
        <QuickChat
          message={messages?.[messages?.length - 1]}
          isQuickMessage={isQuickMessage}
          onSelect={({ message }) => {
            return !isPending ? onSendMessage({ message }) : null;
          }}
          isYesOrNo={messages?.[messages?.length - 1]?.content
            ?.toLowerCase()
            .includes("correct?")}
        />
        {/* EVENT QUICK CHATS */}
        {messages?.[messages?.length - 1]?.trigger === "events_quick_chat" && (
          <EventQuickChats
            onSelect={({ message }) => {
              return !isPending ? onSendMessage({ message }) : null;
            }}
          />
        )}
        {messages?.[messages?.length - 1]?.isTicketPurchase && (
          <EarnMore
            event={messages?.[messages?.length - 1]?.event}
            isEventCard={false}
          />
        )}

        <div ref={targetRef} />
      </Stack>

      <form
        onSubmit={handleSubmit}
        style={{
          padding: "0 20px",
        }}
      >
        <Textarea
          name="message"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
          rows={1}
          minRows={1}
          maxRows={4}
          autosize={true}
          rightSection={
            <Image
              src={"/images/milo/send.svg"}
              alt="Send"
              width={40}
              height={20}
              style={{
                cursor: isPending ? "not-allowed" : "pointer",
                userSelect: "none", // Prevent text selection
              }}
              onMouseDown={(e) => {
                e.preventDefault(); // Prevent default mousedown behavior
                handleSendClick();
              }}
            />
          }
          rightSectionWidth={60}
          className={cssstyles.input}
          styles={{
            input: {
              backgroundColor: "#2C2C3D",
              border: "none",
              color: "white",
              fontSize: "14px",
              fontFamily: "Suisse Intl",
              fontWeight: 400,
              padding: "8px 20px",
              paddingRight: "60px",
              borderRadius: 12,
              marginBottom: 32,
              resize: "none",
              overflow: "hidden",
              "&::placeholder": {
                color: "#F7F7F7",
                opacity: 0.6,
              },
            },
            wrapper: {
              height: "auto",
              position: "relative",
              paddingLeft: 0,
            },
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendClick();
            }
          }}
        />
      </form>
    </Stack>
  );
};

ChatBox.propTypes = {
  messages: PropTypes.array,
  onSendMessage: PropTypes.func,
  isPending: PropTypes.bool,
  onReset: PropTypes.func,
  setMessages: PropTypes.func,
};
